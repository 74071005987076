import React from 'react';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';
import HomePageFull from './home/HomePageFull';
import HomePageMobile from './home/HomePageMobile';

function HomePage() {
  const location = useLocation();
  const { isMobile } = useWindowDimensions();

  const currentPath = location?.pathname;

  if (isMobile) return <HomePageMobile currentPath={currentPath} />;
  return <HomePageFull currentPath={currentPath} />;
}

export default HomePage;
