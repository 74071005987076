import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import NextButton from '../../button/NextButton';
import TextInput from '../../inputs/TextInput';

import MobileFormContainer from '../../layout/MobileFormContainer';
import MobileTextInput from '../../inputs/MobileTextInput';

function VerificationMobile() {
  const [code, setCode] = useState();
  const history = useHistory();

  return (
    <MobileFormContainer>
      <MobileTextInput
        icon={null}
        type="text"
        value={code}
        placeholder="code"
        handleTextChange={setCode}
      />
      <div style={styles.resendCode}>
        Resend Code
      </div>
      <div style={{ padding: '24px 0' }}>
        <NextButton onClick={() => history.push('/qrcode')} isMobile />
      </div>
    </MobileFormContainer>
  );
}

const styles = {
  resendCode: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    opacity: '0.4',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '0px 0',
  },
};

export default VerificationMobile;
