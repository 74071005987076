import React from 'react';
import { useSpring, animated } from 'react-spring';
import FadeTransition from '../../transitions/FadeTransition';

function FormHeaderTitle({ title }) {
  const activeStyles = useSpring({
    from: {
      opacity: 0.3,
    },
    to: {
      opacity: 1,
    },
  });

  const fullActiveStyle = { ...styles.titleText, ...activeStyles };

  return (
    <FadeTransition>
      <div style={styles.row}>
        <div style={styles.linkText}>
          <animated.div style={fullActiveStyle}>
            {title}
          </animated.div>
        </div>
      </div>
    </FadeTransition>
  );
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  linkText: {
    color: 'white',
    textDecoration: 'none',
  },
  titleText: {
    fontFamily: 'Elemental End',
    padding: '0 36px',
    fontSize: '2em',
  },
};

export default FormHeaderTitle;
