import React from 'react';
import { animated, config, useSpring } from 'react-spring';

import About from '../../components/about/About';
import FormHeaderLinks from '../../components/authentication-flow/form-header/FormHeaderLinks';
import Login from '../../components/authentication-flow/login/Login';
import QRcodeForm from '../../components/authentication-flow/qrcode/QRcodeForm';
import Register from '../../components/authentication-flow/register/Register';
import Verification from '../../components/authentication-flow/verification/Verification';
import ContactUs from '../../components/contact/ContactUs';
import BackgroundLayer from '../../components/layout/BackgroundLayer';
import FadeTransition from '../../components/transitions/FadeTransition';
import Navbar from '../../navigator/Navbar';

import POGRSmall from '../../resources/images/Logo symbol.png';
import POGRText from '../../resources/images/Theme=Dark.png';

function HomePageFull({ currentPath }) {
  // let inputForm;
  // switch (currentPath) {
  //   case '/login':
  //     inputForm = <Login />;
  //     break;
  //   case '/register':
  //     inputForm = <Register />;
  //     break;
  //   case '/verification':
  //     inputForm = <Verification />;
  //     break;
  //   case '/qrcode':
  //     inputForm = <QRcodeForm />;
  //     break;
  //   case '/about':
  //     inputForm = <About />;
  //     break;
  //   case '/contact':
  //     inputForm = <ContactUs />;
  //     break;
  //   default:
  //     inputForm = <Login />;
  //     break;
  // }

  const rotateY = useSpring({
    config: {
      duration: 12000
    },
    from: {
      rotateY: 0,
    },
    to: {
      rotateY: 360,
    },
    loop: true,
  });

  return (
    <>
      <div style={styles.container}>
        <animated.img src={'/logo/Tournaments.png'} style={{ ...styles.pogrBrand }} />
        <animated.img src={POGRText} style={{ ...styles.pogrTextBrand }} />
        <div style={styles.pogrText}>Tournaments by pogr</div>
        <div style={styles.pogrSubText}>coming soon</div>
      </div>
      <BackgroundLayer />
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
  },
  pogrBrand: {
    width: '40%',
    maxWidth: '450px',
    padding: '20px',
    opacity: 0.9,
  },
  pogrTextBrand: {
    width: '16%',
    padding: '20px',
  },
  pogrText: {
    color: '#00DDDD',
    padding: '40px 0 0 0',
    letterSpacing: '0.5em',
    fontSize: '1.2em',
    fontFamily: 'ELEMENTAL END',
  },
  pogrSubText: {
    color: '#FFF',
    padding: '8px 0',
    letterSpacing: '0.5em',
    fontSize: '1.2em',
    fontFamily: 'ELEMENTAL END',
  },
};

export default HomePageFull;
