import React, { useState } from 'react';

function MobileTextInput({
  icon, placeholder = 'text here', type = 'text', value, handleTextChange,
}) {
  const [focused, setFocused] = useState(false);
  const selected = value || focused;

  return (
    <div style={styles.inputRow}>
      <input
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={selected ? styles.inputStyle : { ...styles.inputStyle, borderBottom: '3px solid #E9E9EB33' }}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(event) => handleTextChange(event.target.value)}
      />
    </div>
  );
}

const styles = {
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    padding: '8px 0',
  },
  iconStyle: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputStyle: {
    backgroundColor: 'transparent',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    width: '100%',
    margin: '0 48px',
    border: 'none',
    borderBottom: '3px solid #E9E9EB',
    color: 'white',
    fontSize: '16px',
    boxShadow: 'none',
    textAlign: 'center',
    padding: '8px',
    outline: 'none !important',
    transition: '0.3s ease-in',
  },
};

export default React.memo(MobileTextInput);
