import React from 'react';

function About() {
  return (
    <div style={styles.container}>
      Something about POGR
    </div>
  );
}

const styles = {
  container: {
    width: '65%',
    fontFamily: 'Montserrat',
    lineHeight: '1.5em',
    padding: '36px 12px',
  },
};

export default About;
