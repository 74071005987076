import React from 'react';
import Particles from 'react-particles-js';
import ParticleConfig from '../../resources/particle-config/particlesjs-config-2.json';
import POGRSmall from '../../resources/images/Logo symbol.png';

const BackgroundLayer = React.memo(({ children }) => (
  <div style={styles.background}>
    <Particles
      style={{
        position: 'fixed',
        left: '0%',
        top: '0%',
        zIndex: '-5',
      }}
      params={ParticleConfig}
    />
    <img style={styles.bgBrand} src={POGRSmall} alt="bg brand" />
    {children}
  </div>
));

const styles = {
  background: {
    background: 'linear-gradient(180deg, #111 56.23%, #00DDDD 1000%)',
    position: 'fixed',
    left: 0,
    top: 0,
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '-6',
  },
  bgBrand: {
    position: 'fixed',
    zIndex: -1,
    left: '50%',
    top: '50%',
    height: '80%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.05,
  },
};

export default BackgroundLayer;
