import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import NextButton from '../../button/NextButton';
import TextInput from '../../inputs/TextInput';

import FormContainer from '../../inputs/FormContainer';

function Verification() {
  const [code, setCode] = useState();
  const history = useHistory();

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <FormContainer>
        <TextInput
          icon={null}
          type="text"
          value={code}
          placeholder="code"
          handleTextChange={setCode}
        />
        <div style={styles.resendCode}>
          Resend Code
        </div>
      </FormContainer>
      <NextButton onClick={() => history.push('/qrcode')} />
    </div>
  );
}

const styles = {
  resendCode: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    opacity: '0.4',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '12px 0',
  },
};

export default Verification;
