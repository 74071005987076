import React from 'react';
import { animated, useSpring } from 'react-spring';
import AboutMobile from '../../components/about/AboutMobile';
import FormHeaderLinksMobile from '../../components/authentication-flow/form-header/FormHeaderLinksMobile';
import LoginMobile from '../../components/authentication-flow/login/LoginMobile';
import QRcodeFormMobile from '../../components/authentication-flow/qrcode/QRCodeFormMobile';
import RegisterMobile from '../../components/authentication-flow/register/RegisterMobile';
import VerificationMobile from '../../components/authentication-flow/verification/VerificationMobile';
import ContactUs from '../../components/contact/ContactUs';
import BackgroundLayerMobile from '../../components/layout/BackgroundLayerMobile';
import FadeTransition from '../../components/transitions/FadeTransition';

import POGRSmall from '../../resources/images/Logo symbol.png';
import POGRText from '../../resources/images/Theme=Dark.png';

function HomePageMobile({ currentPath }) {
  const rotateY = useSpring({
    config: {
      duration: 12000
    },
    from: {
      rotateY: 0,
    },
    to: {
      rotateY: 360,
    },
    loop: true,
  });

  let inputForm;
  switch (currentPath) {
    case '/login':
      inputForm = <LoginMobile />;
      break;
    case '/register':
      inputForm = <RegisterMobile />;
      break;
    case '/verification':
      inputForm = <VerificationMobile />;
      break;
    case '/qrcode':
      inputForm = <QRcodeFormMobile />;
      break;
    case '/about':
      inputForm = <AboutMobile />;
      break;
    case '/contact':
      inputForm = <ContactUs />;
      break;
    default:
      inputForm = <LoginMobile />;
      break;
  }

  return (
    <>
      <div style={styles.column}>
        <div style={styles.blockOne}>
        </div>
        <div style={styles.blockTwo}>
          <animated.img src={'/logo/Tournaments.png'} style={{ ...styles.pogrTextBrand }} />
          <div style={styles.pogrText}>Tournaments by pogr</div>
          <div style={styles.pogrSubText}>coming soon</div>
        </div>
      </div>
      <BackgroundLayerMobile />
    </>
  );
}

const styles = {
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
    width: '100%',
    height: '100%',
  },
  blockOne: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25vh',
    width: '100%',
    paddingTop: '36px',
    color: 'white',
  },
  blockTwo: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 3,
    zIndex: 5,
    color: 'white',
  },
  brandingImage: {
    height: '80%',
    opacity: 0.9,
    zIndex: 1,
  },
  pogrBrand: {
    width: '16%',
    padding: '20px',
    opacity: 0.9,
  },
  pogrTextBrand: {
    width: '60%',
    padding: '20px',
  },
  pogrText: {
    color: '#00DDDD',
    padding: '12px 0 0 0',
    letterSpacing: '0.5em',
    fontSize: '1.2em',
    textAlign: 'center',
    fontFamily: 'ELEMENTAL END',
  },
  pogrSubText: {
    position: 'fixed',
    bottom: 0,
    color: '#FFF',
    padding: '36px 0',
    letterSpacing: '0.5em',
    fontSize: '1.2em',
    fontFamily: 'ELEMENTAL END',
  },
};

export default HomePageMobile;
