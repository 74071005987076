// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./resources/fonts/Elemental End.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./resources/fonts/Montserrat-Regular.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: transparent;\n}\n\n@font-face {\n  font-family: \"Elemental End\";\n  src: local(\"Elemental End\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: normal;\n}\n\n@font-face {\n  font-family: \"Montserrat\";\n  src: local(\"Montserrat\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n  font-weight: normal;\n}", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;EAClC,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;EAC5B;8DACgE;EAChE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB;8DACoE;EACpE,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: transparent;\n}\n\n@font-face {\n  font-family: \"Elemental End\";\n  src: local(\"Elemental End\"),\n    url(\"./resources/fonts/Elemental\\ End.ttf\") format(\"truetype\");\n  font-weight: normal;\n}\n\n@font-face {\n  font-family: \"Montserrat\";\n  src: local(\"Montserrat\"),\n    url(\"./resources/fonts/Montserrat-Regular.ttf\") format(\"truetype\");\n  font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
