import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import NextButton from '../../button/NextButton';
import TextInput from '../../inputs/TextInput';

import mailIcon from '../../../resources/icons/mail-icon.svg';
import keyIcon from '../../../resources/icons/key-solid.svg';
import FormContainer from '../../inputs/FormContainer';
import MobileTextInput from '../../inputs/MobileTextInput';

function LoginMobile() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleNextClick = () => {
    history.push('/verification');
  };

  return (
    <div style={styles.container}>
      <MobileTextInput
        icon={mailIcon}
        type="text"
        value={email}
        placeholder="Your Email"
        handleTextChange={setEmail}
      />
      <MobileTextInput
        icon={keyIcon}
        type="password"
        value={password}
        placeholder="Your Password"
        handleTextChange={setPassword}
      />
      <div style={{ padding: '16px 0' }}>
        <NextButton onClick={handleNextClick} isMobile />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
};

export default LoginMobile;
