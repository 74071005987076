import React from 'react';

function AboutMobile() {
  return (
    <div style={styles.container}>
      Something About POGR
    </div>
  );
}

const styles = {
  container: {
    width: '85%',
    fontFamily: 'Montserrat',
    lineHeight: '1.3em',
    textAlign: 'center',
    padding: '12px 0px',
  },
};

export default AboutMobile;
