import React from 'react';
import Particles from 'react-particles-js';
import ParticleConfig from '../../resources/particle-config/particlesjs-config-2-mobile.json';

const BackgroundLayerMobile = React.memo(({ children }) => (
  <div style={styles.background}>
    <Particles
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: '1',
      }}
      params={ParticleConfig}
    />
    {children}
  </div>
));

const styles = {
  background: {
    background: 'linear-gradient(180deg, #000000 0.01%, #00DDDD 600%)',
    minHeight: '100vh',
    height: '100%',
    minWidth: '100vw',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '0',
    position: 'fixed',
    left: 0,
    top: 0,
    margin: 0,
  },
};

export default BackgroundLayerMobile;
