import React from 'react';
import FormHeaderNavMobile from './FormHeaderNavMobile';
import FormHeaderTitle from './FormHeaderTitle';
import FormHeaderTitleMobile from './FormHeaderTitleMobile';

function FormHeaderLinksMobile({ currentPath }) {
  let formHeader = null;
  switch (currentPath) {
    case '/':
      formHeader = <FormHeaderNavMobile />;
      break;
    case '/register':
      formHeader = <FormHeaderNavMobile />;
      break;
    case '/verification':
      formHeader = <FormHeaderTitleMobile title="Verification" />;
      break;
    case '/qrcode':
      formHeader = <FormHeaderTitleMobile title="Code" />;
      break;
    case '/about':
      formHeader = <FormHeaderTitleMobile title="about us" />;
      break;
    case '/contact':
      formHeader = null;
      break;
    default:
      break;
  }

  return (formHeader);
}

export default FormHeaderLinksMobile;
