/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

function NextButton({ onClick, isMobile }) {
  const styles = {
    buttonContainer: {
      cursor: 'pointer',
      color: 'white',
      fontSize: isMobile ? '18px' : '26px',
      fontFamily: 'Elemental End',
    },
  };

  return (
    <div style={styles.buttonContainer} onKeyDown={() => {}} onClick={onClick}>
      Next &gt;
    </div>
  );
}

export default NextButton;
