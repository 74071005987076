import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import HomePage from '../pages/HomePage';

function AppNavigator() {
  return (
    <Router>
      <Switch>
        <Route exact key="default-page" path="/nts" component={HomePage} />
        <Redirect to="/nts" />
      </Switch>
    </Router>
  );
}

export default AppNavigator;
