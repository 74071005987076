/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode';

import NextButton from '../../button/NextButton';
import MobileFormContainer from '../../layout/MobileFormContainer';

function QRcodeFormMobile() {
  const history = useHistory();
  const [copyClicked, setCopyClicked] = useState(false);
  const [QRCodeLink, setQRCodeLink] = useState();
  const [QRCodeString, setQRCodeString] = useState('O5WCCJSKOZWHMRJJFBTUOVRQG5KHI4RFHFND6SKFN5YXAT3CNBRA');

  const generateQR = async (text) => {
    const opts = {
      errorCorrectionLevel: 'M',
      type: 'image/jpeg',
      width: 200,
      quality: 0.3,
      margin: 1,
      color: {
        dark: '#000',
        light: '#FFF',
      },
    };
    try {
      setQRCodeLink(await QRCode.toDataURL(text, opts));
    } catch (err) {
      console.error(err);
    }
  };

  const copyToClipboard = (text) => {
    setCopyClicked(true);
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    generateQR(QRCodeString);
  }, []);

  return (
    <MobileFormContainer>
      {QRCodeLink
        ? <img src={QRCodeLink} alt="qrcode" /> : null }
      <div style={styles.manualCode}>{QRCodeString}</div>
      <div
        style={styles.copyText}
        onClick={
          () => copyToClipboard(QRCodeString)
        }
      >
        {copyClicked ? 'added to clipboard!' : 'copy'}
      </div>
      <NextButton onClick={() => history.push('/')} isMobile />
    </MobileFormContainer>
  );
}

const styles = {
  manualCode: {
    fontFamily: 'Montserrat',
    fontSize: '8px',
    padding: '24px 0 12px 0',
  },
  copyText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    paddingBottom: '24px',
    cursor: 'pointer',
  },
};

export default QRcodeFormMobile;
