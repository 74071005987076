/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

function FormHeaderNav() {
  const { pathname } = useLocation();
  const history = useHistory();
  const activeStyles = useSpring({
    from: {
      opacity: 0.3,
    },
    to: {
      opacity: 1,
    },
  });

  const deactiveStyles = useSpring({
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.3,
    },
  });

  const handleLinkClick = (url) => {
    if (pathname === url) return;
    history.push(url);
  };

  const fullActiveStyle = { ...styles.titleText, ...activeStyles, cursor: 'default' };
  const fullDeactiveStyle = { ...styles.titleText, ...deactiveStyles, cursor: 'pointer' };

  return (
    <div style={styles.row}>
      <div style={styles.linkText1} onClick={() => handleLinkClick('/')}>
        <animated.div style={pathname === '/' ? fullActiveStyle : fullDeactiveStyle}>
          LOGIN
        </animated.div>
      </div>
      <div style={styles.linkText2} onClick={() => handleLinkClick('/register')}>
        <animated.div style={pathname === '/register' ? fullActiveStyle : fullDeactiveStyle}>
          Sign Up
        </animated.div>
      </div>
    </div>
  );
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  linkText1: {
    color: 'white',
    textDecoration: 'none',
    borderRight: '3px solid white',
  },
  linkText2: {
    color: 'white',
    textDecoration: 'none',
    borderLeft: '3px solid white',
  },
  titleText: {
    fontFamily: 'Elemental End',
    padding: '0 36px',
    fontSize: '2em',
  },
};

export default FormHeaderNav;
