import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import NextButton from '../../button/NextButton';

import mailIcon from '../../../resources/icons/mail-icon.svg';
import keyIcon from '../../../resources/icons/key-solid.svg';
import phoneIcon from '../../../resources/icons/phone-solid.svg';
import MobileTextInput from '../../inputs/MobileTextInput';

function RegisterMobile() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [phone, setPhone] = useState();
  const history = useHistory();

  return (
    <div style={styles.container}>
      <MobileTextInput
        icon={mailIcon}
        type="text"
        value={email}
        placeholder="Your Email"
        handleTextChange={setEmail}
      />
      <MobileTextInput
        icon={keyIcon}
        type="password"
        value={password}
        placeholder="Your Password"
        handleTextChange={setPassword}
      />
      <MobileTextInput
        type="password"
        value={password2}
        placeholder="Confirm Password"
        handleTextChange={setPassword2}
      />
      <MobileTextInput
        icon={phoneIcon}
        type="phone"
        value={phone}
        placeholder="Your Phone Number"
        handleTextChange={setPhone}
      />
      <div style={{ padding: '16px 0' }}>
        <NextButton onClick={() => history.push('/verification')} isMobile />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
};

export default RegisterMobile;
